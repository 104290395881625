import { Fragment, useRef, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import { setLastVisitedCatID, setLastVisitedSearch, selectChildrenCatalogs } from 'srs.sharedcomponents/lib/esm/redux/slices/productlistSlice'
import { ILeftCatalogItem } from 'srs.sharedcomponents/lib/esm/models/srs.leftCatalogItem.model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { stringToBoolean } from 'srs.sharedcomponents/lib/esm/utils/helpers'
import { useAppSelector } from 'srs.sharedcomponents/lib/esm/redux/hooks'
import {
  isShowAllMenuInCapitals,
} from 'srs.sharedcomponents/lib/esm/redux/slices/welcomeSlice'
import * as S from './styles'
export interface ILeftMenuProps {
  items: ILeftCatalogItem[]
  toggleLeftSideMobileMenu?: () => void
}

const LeftMenuComponent = ({ items, toggleLeftSideMobileMenu }: ILeftMenuProps) => {
  const { search } = useLocation()
  const values = queryString.parse(search)
  const liCnt = useRef(0)
  const dispatch = useDispatch()
  const [categoryExpanded, setCategoryExpanded] = useState([0])
  const menuCollapsedByDefault = stringToBoolean(process.env.REACT_APP_COLLAPSE_LEFT_MENU)
  const showCapitalMenu = useAppSelector(isShowAllMenuInCapitals)
  const childrenCatalogs = useAppSelector(selectChildrenCatalogs)
  const searchParams = new URLSearchParams(search)
  let searchParam = decodeURI(searchParams.get('search') ?? '').trim()

  /**
   * Toggle category list
   * @param id {Number} id of parent category
   */
  const toggleListWithCategory = (id: number) => {
    setCategoryExpanded(categoryExpanded.concat(id))
    if (categoryExpanded.includes(id)) {
      setCategoryExpanded(categoryExpanded.filter((value) => value !== id))
    }
  }

  const validateCategorySelectedItem = () => {
    let selectedItem: ILeftCatalogItem | undefined

    if (values.CatID) {
      for (const category of items) {
        if (category.items) {
          selectedItem = category.items.find((item) => Number(values.CatID) === item.id)
          if (selectedItem) {
            break
          }
        }
      }
    }
    return selectedItem
  }

  const expandPreviousSelectedMainCategory = () => {
    if (menuCollapsedByDefault) {
      let selectedItem = validateCategorySelectedItem()

      if (selectedItem) {
        let parentId = Number(selectedItem.parentId)
        let selectedItemCategory = items.find((item) => parentId === item.id)

        if (selectedItemCategory) {
          toggleListWithCategory(parentId)
        }
      }
    }
  }

  const toggleOnClick = (id: number) => {
    dispatch(setLastVisitedCatID(id))
    dispatch(setLastVisitedSearch(null))
    toggleLeftSideMobileMenu?.()
  }

  useEffect(() => {
    expandPreviousSelectedMainCategory()
  }, [])

  const getTagFromQuerystring = (querystring: string | (string | null)[] | null) => {
    if(!querystring) return ''
    if(typeof querystring === 'object') {
      return ''
    }
    if(typeof querystring === 'string') {
      const parsed = queryString.parse(querystring)
      return parsed.TAG as string
    }
    return ''
  }

  const isMenuLabelEqualTag = (label: string) => {
    const querystringFromURL = window.location.search
    return getTagFromQuerystring(querystringFromURL) === label
  }

  const labelIsInTheCategoryList = (label: string) => {
    return items.some((item) => item.label === label)
  }

  const generateMainCats = (d: ILeftCatalogItem[]) => {
    if(!labelIsInTheCategoryList(getTagFromQuerystring(window.location.search))) {
      return null
    }
    return d?.map((m: ILeftCatalogItem) => (
      <>
        {!isMenuLabelEqualTag(m.label) && null}
        {isMenuLabelEqualTag(m.label) && <Fragment key={liCnt.current++}>
          <S.LeftMenuMainItem
            className="list-group-item justify-content-between d-flex flex-1"
            id={'categoryMenuOption_' + m.id.toString()}
          >
            {m.items?.length === 0 ? (
              <Link to={`/ProductListCatalog?TAG=${m.label}`}>
                <b>{showCapitalMenu ? m.label.toUpperCase() : m.label}</b>
              </Link>
            ) : (
                <b>
                  {showCapitalMenu ? m.label.toUpperCase() : m.label}
                </b>
            )}
          </S.LeftMenuMainItem>
          {generateSubCats(m.items, m.label)}
        </Fragment>}
      </>
    ))
  }
  const getCollapseFromEnv = (parentId: number | null | undefined) => {
    if (menuCollapsedByDefault) {
      return categoryExpanded.includes(Number(parentId)) ? '' : 'collapse'
    } else {
      return categoryExpanded.includes(Number(parentId)) ? 'collapse' : ''
    }
  }

  const generateSubCats = (d: ILeftCatalogItem[] | undefined, parentName: string) => {
    return d?.map((s: ILeftCatalogItem) => (
      <S.LeftMenuStyleComponent
        id={'subcategoryMenuOption_' + s.id.toString()}
        key={liCnt.current++}
        className={`${getCollapseFromEnv(s.parentId)} list-group-item`}
        activated={Number(values.CatID) === s.id}
        to={`/ProductListCatalog?TAG=${s.label}`}
        onClick={() => toggleOnClick(s.id)}
      >
        {showCapitalMenu ? s.label.toUpperCase() : s.label} ({s?.count})
      </S.LeftMenuStyleComponent>
    ))
  }

  const getMainCategoryContent = (items: ILeftCatalogItem[]) => {
    const mainCats = generateMainCats(items)
    if(!mainCats || mainCats.length === 0) {
      const labelUrl = getTagFromQuerystring(window.location.search)
      return (
        <S.LeftMenuMainItem
          className="list-group-item justify-content-between d-flex flex-1"
          id={'categoryMenuOption_0'}
        >
          <b>
            {showCapitalMenu ? labelUrl.toLocaleUpperCase() :labelUrl}
          </b>
        </S.LeftMenuMainItem>
      )
    }
    return mainCats
  }

  const getChidrenCatalogsMainCategoryContent = () => {
    
    return (
      <>
        <S.LeftMenuMainItem
          className="list-group-item justify-content-between d-flex flex-1"
          id={'categoryMenuOption'}
        >
        </S.LeftMenuMainItem>
        {childrenCatalogs?.map((s: ILeftCatalogItem) => (
          <Fragment key={liCnt.current++}>
            <S.LeftMenuStyleComponent
              id={'subcategoryMenuOption_' + s.id.toString()}
              key={liCnt.current++}
              className={`${getCollapseFromEnv(s.parentId)} list-group-item`}
              activated={Number(values.CatID) === s.id}
              to={`/ProductListCatalog?TAG=${s.label}`}
              onClick={() => toggleOnClick(s.id)}
            >
             {showCapitalMenu ? s.label.toUpperCase() : s.label} ({s?.count})
            </S.LeftMenuStyleComponent>
          </Fragment>
        ))}
      </>
    )
  }

  return (
    <ul className="list-group list-group-flush left-menu">
      <S.LeftMenuHeader className="d-flex justify-content-between">
        <h3>Categories</h3>
        <button className="menu-button left-menu-close btn btn-light d-lg-none ml-2">
          <FontAwesomeIcon icon={faTimes} onClick={toggleLeftSideMobileMenu} />
        </button>
      </S.LeftMenuHeader>
      {!searchParam && getMainCategoryContent(items)}
      {searchParam && getChidrenCatalogsMainCategoryContent()}
    </ul>
      
    
  )
}
export default LeftMenuComponent
