import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import * as S from './Styles';
import { useTranslate } from 'srs.sharedcomponents/lib/esm/hooks/useTranslate';
import { FormInputDropdown } from 'srs.sharedcomponents/lib/esm/components/srs.formInputDropdown.component';

interface IDropDownOption {
    label: string;
    value: string;
}
interface IFormSchema {
    buttons: string;
    frequency: string;
    type: string;
    keycodeRange: string;
    exportDomestic: string;
}
interface IFacetedSearchProps {
    mainZone?: boolean;
    isKeyFinder?: boolean;
}
const mockFacetOptions = {
    buttons: {
        level: 'Number of Buttons',
        options: [
            { label: '3 buttons', value: '3 buttons' },
            { label: '4 buttons', value: '4 buttons' },
            { label: '5 buttons', value: '5 buttons' },
        ] as IDropDownOption[],
    },
    frequency: {
        level: 'Frequency',
        options: [
            { label: '315 MHz', value: '315 MHz' },
            { label: '433 MHz', value: '433 MHz' },
            { label: '868 MHz', value: '868 MHz' },
        ] as IDropDownOption[],
    },
    type: {
        level: 'Type',
        options: [
            { label: 'FOB', value: 'FOB' },
            { label: 'Smart Key', value: 'Smart Key' },
            { label: 'Remote', value: 'Remote' },
        ] as IDropDownOption[],
    },
    keycodeRange: {
        level: 'Keycode Range',
        options: [
            { label: '00001 - 04000', value: '00001 - 04000' },
            { label: '04001 - 08000', value: '04001 - 08000' },
            { label: '08001 - 12000', value: '08001 - 12000' },
        ] as IDropDownOption[],
    },
    exportDomestic: {
        level: 'Export Domestic',
        options: [
            { label: 'Export', value: 'Export' },
            { label: 'Domestic', value: 'Domestic' },
        ] as IDropDownOption[],
    },
} as const;
type MockFacetKeys = keyof typeof mockFacetOptions;

const RefineYourSearch: React.FC<IFacetedSearchProps> = ({ mainZone, isKeyFinder }) => {
    const [formInputs, setFormInputs] = useState<IFormSchema | undefined>(undefined);
    const { translate } = useTranslate();
    const schema = yup.object().shape({
        buttons: yup.string(),
        frequency: yup.string(),
        type: yup.string(),
        keycodeRange: yup.string(),
        exportDomestic: yup.string(),
    });
    const methods = useForm<IFormSchema>({
        defaultValues: formInputs,
        resolver: yupResolver(schema),
        mode: 'all',
    });
    const { control, handleSubmit, reset } = methods;

    const onSubmit = useCallback((data: IFormSchema) => {
        console.log('Submitted Form Data:', data);
    }, []);

    useEffect(() => {
        const initialValues = {
            buttons: '3 buttons',
            frequency: '315 MHz',
            type: 'FOB',
            keycodeRange: '00001 - 04000',
            exportDomestic: 'Export',
        };
        setFormInputs(initialValues);
        reset(initialValues);
    }, [reset]);

    return (
        <div>
            <S.logo className="pb-3">{translate('keyFinder.refineyoursearch')}</S.logo>
            <S.para className="pb-3">{translate('keyFinder.yousearch')}</S.para>
            <S.SelectsForm onSubmit={handleSubmit(onSubmit)} mainImage={mainZone} isKeyFinder={isKeyFinder}>
                {Object.keys(mockFacetOptions).map((key) => {
                    const field = mockFacetOptions[key as MockFacetKeys];
                    return (
                        <div key={key}>
                            <FormInputDropdown
                                id={key}
                                name={key}
                                control={control}
                                label={field.level}
                                options={field.options}
                                schema={schema}                                
                            />
                        </div>
                    );
                })}
            </S.SelectsForm>
        </div>
    );
};
export default RefineYourSearch;